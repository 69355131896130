import { useFormik } from 'formik';
import * as Yup from 'yup';

import { hook, bindings } from '@vl/redata';

export default bindings({
  formContainer: {
    rules: [
      [
        'data',
        {
          data: {
            form: hook((ctx) => {
              const form = useFormik({
                initialValues: {
                  email: '',
                },
                onSubmit: async (values, actions) => {
                  try {
                    const res = await ctx.apply('authModel.forgotPassword', values.email);
                    form.setStatus({
                      success: {
                        message: res.message,
                      },
                    });
                  } catch (error) {
                    actions.setStatus({
                      error,
                    });
                  }
                },
                validationSchema: Yup.object().shape({
                  email: Yup.string()
                    .email('Invalid email')
                    .required(ctx.apply('i18n.t', 'Form.required')),
                }),
              });

              return form;
            }),
            canSubmit: hook((ctx) => {
              return ctx.get('form.isValid') && !ctx.get('form.isSubmitting');
            }),
          },
        },
      ],
    ],
  },
});
