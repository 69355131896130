import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Typography } from 'antd';
import { ctx } from '@vl/redata';
import { Image } from '@uz/unitz-components-web/Image';
import _ from 'lodash';

const { Title } = Typography;

const View8 = () => {
  return (
    <DIV className="brandAuth">
      <div className="mt-7 lg:mt-20">
        <div className="p-1 lg:p-6">
          <div className="w-16 lg:w-32 h-16 lg:h-32 mx-auto lg:ml-4 mb-0 md:mb-10 lg:mb-11">
            <Image
              size="cover"
              src={ctx.apply('ctf.findImage', ctx.get('sectionData.section'), 'image', '')}
              alt="logo"
            />
          </div>
          <div className="hidden md:text-center lg:text-left sm:block">
            <Title level={4} className="font-semibold text-main">
              {_.get(ctx.get('sectionData.section'), 'shortText', '')}
            </Title>
          </div>
        </div>
      </div>
    </DIV>
  );
};

export default displayName(View8);
