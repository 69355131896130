import React from 'react';
import { Button, Row, Col, Alert, Space } from 'antd';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Link } from '@uz/unitz-components-web/Link';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import { Input, Form, FormItem } from 'formik-antd';
import gstyles from '@vl/gstyles';
import CustomInput from '@uz/unitz-components-web/CustomInput';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
};

export default () => (
  <DIV className="formContainer">
    <div className="rounded-lg bg-white p-4 sm:p-6 shadow-md">
      <h3 className="mb-6 text-2xl font-semibold text-center text-brandb500">
        {ctx.apply('i18n.t', 'Profile.resetPassword')}
      </h3>
      <FormProvider form={ctx.get('form')}>
        <Form {...layout} className="login-form" id="login-form">
          <Row>
            <Col className="formStatus" span={24}>
              {!!ctx.get('form.status.error') && (
                <div className="py-2">
                  <Alert message={ctx.get('form.status.error.message')} type="error" />
                </div>
              )}
              {!!ctx.get('form.status.success') && (
                <div className="py-2">
                  <Alert message={ctx.get('form.status.success.message')} type="success" />
                </div>
              )}
            </Col>
          </Row>
          <FormItem name="email">
            <CustomInput>
              <Input
                className="rounded-lg"
                name="email"
                placeholder={ctx.apply('i18n.t', 'Login.email')}
                size="medium"
              />
            </CustomInput>
          </FormItem>
          <Row>
            <Col span={24}>
              <Button
                className="text-white500 shadow-sm"
                htmlType="submit"
                type="primary"
                size="large"
                id="submit"
                disabled={!ctx.get('canSubmit')}
                loading={ctx.get('form.isSubmitting')}
                block
              >
                <span id="button-text">{ctx.apply('i18n.t', 'Profile.send')}</span>
              </Button>
            </Col>
          </Row>
        </Form>
      </FormProvider>
      <div className="mt-3 relative text-center">
        <div className="absolute transform -translate-x-1/2 -translate-y-1/2 left-2 top-1/2">
          <Link to={ctx.apply('routeStore.toLocale', '/login')}>
            {gstyles.icons({ name: 'arrow-left2', size: 18, fill: gstyles.colors.brandb500 })}
          </Link>
        </div>
        <Space>
          <div className="font-normal text-main mb-0 text-base">{ctx.apply('i18n.t', 'Login.signupQuestion')}</div>
          <Link to={ctx.apply('routeStore.toLocale', '/login')}>
            <h5 className="mb-0 font-medium text-base text-brandb500">{ctx.apply('i18n.t', 'Login.signupRedirect')}</h5>
          </Link>
        </Space>
      </div>
    </div>
  </DIV>
);
