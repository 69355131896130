import React from 'react';

import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import FormForgot from '@uz/unitz-components-web/FormForgot';
import BrandAuth from '@uz/unitz-components-web/BrandAuth';

const Index = () => {
  // @TODO: validate missing required input
  return (
    <DIV className="pageContainer">
      <div className="sm:wrapper app-row my-0 md:mx-28 sm:my-11 grid grid-cols-1 lg:grid-cols-2 gap-5 xl:mx-auto">
        <BrandAuth />
        <FormForgot />
      </div>
    </DIV>
  );
};

export default displayName(Index);
